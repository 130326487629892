<template>
  <a-card :border="false">
    <div class="realtor">
      <!-- 查询表单 -->
      <a-form-model ref="searchForm" layout="horizontal" :label-col="{ xs: 8 }" :wrapper-col="{ xs: 16 }">
        <a-row :gutter="8">
          <a-col :md="8" :xl="6" :xxl="6" :sm="12">
            <a-form-model-item label="标签名称">
              <a-input v-model="searchData.tagName" placeholder="请输入标签名称"></a-input>
            </a-form-model-item>
          </a-col>
          <a-col :md="8" :xl="6" :xxl="6" :sm="12">
            <a-form-item label="状态">
              <a-select placeholder="请选择" v-model="searchData.flagEnable">
                <a-select-option value="">全部</a-select-option>
                <a-select-option value="true">开启</a-select-option>
                <a-select-option value="false">关闭</a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
          <a-col :xxl="6" :xl="5" :md="8" sm="2">
            <a-form-model-item :label-col="{ span: 0 }" :wrapper-col="{ span: 24 }">
              <a-button type="primary" @click="getDataFilter()" icon="search">查询</a-button>
              <a-button type="default" @click="reset()" icon="sync">重置</a-button>
            </a-form-model-item>
          </a-col>
        </a-row>
      </a-form-model>
      <div>
        <a-button type="primary" @click="toHandler('check')" icon="copy" v-if="isCheck">查看</a-button>
      </div>
      <!-- 数据表格 -->
      <a-table
        :loading="tableLoading"
        :row-selection="rowSelection"
        :rowKey="(record) => record.id"
        :columns="columns"
        :data-source="tableData"
        bordered
        :pagination="page"
        @change="changeTable"
        :customRow="changeTableRow"
        :scroll="{ x: 1300 }"
      >
        <span slot="name" slot-scope="text">
          <a-tooltip>
            <template slot="title"> {{ text }} </template> {{ text }}
          </a-tooltip>
        </span>
        <span slot="index" slot-scope="text, row, index">{{ index + 1 }}</span>
        <span slot="flagEnable" slot-scope="item, row">
          <!-- <a-switch
          v-model="row.flagEnable"
          checked-children="启用"
          @change="onStatusChange(item, row)"
          un-checked-children="关闭"
          default-checked
        /> -->
          <a-tag v-if="row.flagEnable == false" color="#909399">禁用</a-tag>
          <a-tag v-if="row.flagEnable == true" color="#67c23a">在用</a-tag>
        </span>
        <span slot="tagLogo" slot-scope="item">
          <ZoomMedia style="height: 20px" :value="item"></ZoomMedia>
        </span>
        <span slot="proCount" slot-scope="text, row" style="cursor: pointer; color: blue">
          <u @click="onTagList(row)">{{ text }}</u>
        </span>
        <span slot="action" slot-scope="text, record">
          <a @click="handleEdit(record)">编辑</a>
          <a-divider type="vertical" />
          <a @click="delByIds([record.id])">删除</a>
          <a-divider type="vertical" />
          <a @click="onCheck(record)">查看</a>
        </span>
      </a-table>
      <!-- 编辑表单 -->
      <ProductTag-edit-modal ref="ProductTagEditModal" @reload="getData"></ProductTag-edit-modal>
      <TagList ref="tagList"></TagList>
    </div>
  </a-card>
</template>

<script>
import { columns } from './components/colums.js'
import ProductTagEditModal from './components/ProductTagEditModal.vue'
import TagList from './components/TagList.vue'
import { delProductTag, listProductTag, selectByIdProductTag } from './api/ProductTagApi'
import { checkPermission } from '@/utils/permissions'

const pageSource = {
  current: 1,
  pageSize: 10,
  total: 0,
}
export default {
  name: 'productTag',
  components: {
    ProductTagEditModal,
    TagList,
  },
  data() {
    return {
      columns: columns,
      tableData: [],
      searchData: {}, // 搜索条件
      scroll: { y: 600 },
      tableLoading: false, //表格loading
      page: Object.assign({}, pageSource),
      selectedRowKeys: [],
      selectedRows: [],
      isEdit: checkPermission('product:tag:edit'),
      isAdd: checkPermission('product:tag:add'),
      isDelete: checkPermission('product:tag:delete'),
      isEnabled: checkPermission('product:tag:enabled'),
      isDisabled: checkPermission('product:tag:disabled'),
      isCheck: checkPermission('product:tag:check'),
    }
  },
  computed: {
    rowSelection() {
      const _this = this
      return {
        fixed: true,
        type: 'radio', //"radio"
        selectedRowKeys: this.selectedRowKeys,
        onChange: (selectedRowKeys, selectedRows) => {
          _this.selectedRowKeys = selectedRowKeys
          _this.selectedRows = selectedRows
        },
      }
    },
  },
  methods: {
    /**
     * 获取表格数据
     */
    getData() {
      this.selectedRows = []
      this.selectedRowKeys = []
      this.tableLoading = true
      listProductTag({
        pageNumber: this.page.current,
        pageSize: this.page.pageSize,
        ...this.searchData,
      })
        .then((res) => {
          if (res.code === 200) {
            const { total, records } = res.body
            this.page.total = total
            this.tableData = records
          }
        })
        .finally(() => (this.tableLoading = false))
    },
    /**
     * 筛选查询
     */
    getDataFilter() {
      this.page = Object.assign({}, pageSource)
      this.getData()
    },
    /**
     * 重置
     */
    reset() {
      this.searchData = {}
      this.page = Object.assign({}, pageSource)
      this.getData()
    },
    /**
     * 更改分页
     */
    changeTable(pagination) {
      this.page = pagination
      this.getData()
    },
    /**
     * 点击行选中
     */
    changeTableRow(record) {
      return {
        on: {
          click: (e) => {
            console.log(e)
            if (this.selectedRowKeys[0] === record.id) {
              this.selectedRowKeys = []
              this.selectedRows = []
            } else {
              this.selectedRowKeys = [record.id]
              this.selectedRows = [record]
            }
          },
        },
      }
    },
    onTagList(item) {
      const _this = this
      _this.$refs.tagList.isShow(item)
    },
    // 编辑
    handleEdit(item) {
      const _this = this
      _this.$refs.ProductTagEditModal.setRowData(item, 'edit')
    },
    // 修改是否启动
    onStatusChange(status) {
      const that = this
      if (that.selectedRows.length === 0) {
        that.$notification.info({
          message: '请选择一条记录',
        })
        return
      }
      that.axios
        .post('/api/product/system/tag/editFlagEnable', { id: that.selectedRows[0].id, flagEnable: status })
        .then((res) => {
          that.getData()
          that.$notification.success({ message: res.message })
        })
    },
    // 删除
    delByIds(id) {
      const _this = this
      _this.$confirm({
        title: '删除警告',
        content: '确认要删除此条记录吗?',
        okText: '确认',
        cancelText: '取消',
        async onOk() {
          const res = await delProductTag(id)
          if (res.code === 200) {
            _this.$notification.success({ message: res.message })
            _this.getData()
          } else {
            _this.$notification.error({ message: res.message })
          }
        },
        onCancel() {},
      })
    },
    // 查看
    onCheck(item) {
      const _this = this
      _this.$refs.ProductTagEditModal.setRowData(item, 'check')
    },
    toHandler(name) {
      const _this = this
      if (name === 'add') {
        _this.$refs.ProductTagEditModal.setRowData({}, 'add')
        return
      }
      if (_this.selectedRows.length === 0) {
        _this.$notification.info({
          message: '请选择一条记录',
        })
        return
      }
      switch (name) {
        case 'edit':
          _this.$refs.ProductTagEditModal.setRowData(_this.selectedRows[0], 'edit')
          break
        case 'del':
          _this.$confirm({
            title: '删除警告',
            content: '确认要删除此条记录吗?',
            okText: '确认',
            cancelText: '取消',
            async onOk() {
              const res = await delProductTag(_this.selectedRows[0].id)
              if (res.code === 200) {
                _this.$notification.success({ message: res.message })
                _this.getData()
              } else {
                _this.$notification.error({ message: res.message })
              }
            },
            onCancel() {},
          })
          break
        case 'check':
          _this.$refs.ProductTagEditModal.setRowData(_this.selectedRows[0], 'check')
          break
      }
    },
  },
  created() {
    this.getData()
  },
}
</script>

<style lang="less" scoped>
.ant-btn {
  margin-right: 8px;
  margin-bottom: 12px;
}
</style>
